import moment from 'moment';

export function getDateStringFromTimestamp(timestamp, pattern = 'DD.MM.YYYY HH:mm') {
  if (timestamp) {
    if (timestamp.seconds) {
      return moment(new Date(timestamp.seconds * 1000)).format(pattern);
    }
    if (timestamp._seconds) {
      return moment(new Date(timestamp._seconds * 1000)).format(pattern);
    }
  }
  return '';
}

export function getDateStringFromDate(date = new Date(), pattern = 'YYYYMMDD_HHmmss') {
  return moment(date).format(pattern);
}

export function getCurDateString(pattern = 'YYYYMMDD_HHmmss') {
  return moment(new Date()).format(pattern);
}

export function isFutureTimestamp(timestamp) {
  if (timestamp) {
    if (timestamp.seconds) {
      return timestamp.seconds * 1000 > Date.now();
    }
    if (timestamp._seconds) {
      return timestamp._seconds * 1000 > Date.now();
    }
  }
  return false;
}

export function getDiffFromTimestamp(timestamp) {
  if (timestamp) {
    if (timestamp.seconds) {
      return Date.now() - timestamp.seconds * 1000;
    } else if (timestamp._seconds) {
      return Date.now() - timestamp._seconds * 1000;
    }
  }
  return -1;
}

export function isMobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
}

export function getPlatform() {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.includes('android')) {
    return 'Android';
  } else if (userAgent.includes('iphone') || userAgent.includes('ipad') || userAgent.includes('ipod')) {
    return 'iOS';
  } else {
    return 'Desktop';
  }
}

export function getCountryCityFromAddress(address) {
  if (address) {
    const myArray = address.split(' ')
    var countryCity = '';
    myArray.slice().reverse().forEach((element, index) => {
      if (typeof element !== 'number' && index < 3) {
        countryCity = element + ' ' + countryCity;
      }
    });
    return countryCity;
  } else {
    return '';
  }
}

export function isValidEmail(email) {
  return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export function isValidName(value) {
  return value.match(/^[0-9A-Za-z\S]+$/);
}

export function getEventParam(eventInfo) {
  if (eventInfo && eventInfo.name && eventInfo.invoiceNumber) {
    return eventInfo.name.trim().replaceAll(/[^\w\s]/gi, '').replaceAll(/\s\s+/g, ' ').replaceAll(/\s/g, '-') + '-' + eventInfo.invoiceNumber;
  } else {
    return '';
  }
}

export function toFileName(value) {
  return value.trim().replaceAll(/[^\w\s]/gi, '').replaceAll(/\s\s+/g, ' ').replaceAll(/\s/g, '-');
}

export function getLocalStorageInfo(key) {
  const jsonString = localStorage.getItem(key);
  if (jsonString) {
    return JSON.parse(jsonString);
  } else {
    return null;
  }
}

export function toInt(value) {
  return parseInt(value) || 0;
}

export function getRoundValue(value) {
  if (!value) {
    return 0;
  }
  return Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100;
}

export function getDecimalFormat(value, forceFloat = false) {
  if (!value) {
    value = 0;
  }
	const options = {
		minimumFractionDigits: forceFloat ? 2 : 0,
		maximumFractionDigits: forceFloat ? 2 : 0
	};
	return Number(value).toLocaleString('de-DE', options);
}

export function getPriceFormat(value, forceInt = false) {
  if (!value) {
    value = 0;
  }
  const options = {
    minimumFractionDigits: forceInt ? 0 : 2,
		maximumFractionDigits: forceInt ? 0 : 2,
    style: 'currency',
    currency: 'EUR'
  }
	return Number(value / 100).toLocaleString('de-DE', options);
}

export function zeroPad(num, places = 3) {
  return String(num).padStart(places, '0');
}

export function showFunctionError(self, data) {
  switch (data) {
    case self.RESULT_UNKNOWN_ERROR:
      self.$toast.error(self.$t('alert_unknown_error'));
      return;
    case self.RESULT_INVALID_PARAM:
      self.$toast.error('Invalid Parameter.');
      return;
    case self.RESULT_INCORRECT_PASSWORD:
      self.$toast.error(self.$t('alert_incorrect_password'));
      return;
    case self.RESULT_USER_DELETED:
      self.$toast.error(self.$t('alert_your_account_has_been_deleted'));
      return;
    case self.RESULT_DUPLICATED_EMAIL:
      self.$toast.error(self.$t('alert_email_already_exist'));
      return;
    case self.RESULT_DUPLICATED_PHONE:
      self.$toast.error(self.$t('alert_phone_number_already_exist'));
      return;
    case self.RESULT_DUPLICATED_USERNAME:
      self.$toast.error(self.$t('alert_username_already_exist'));
      return;
    case self.RESULT_DUPLICATED_TRANSACTION:
      self.$toast.error('Duplicated transaction!!!');
      return;
    case self.RESULT_TICKET_ALREADY_USED:
      self.$toast.error('This ticket is already used.');
      return;
    case self.RESULT_TICKET_ALREADY_REFUNDED:
      self.$toast.error('This ticket is already refunded.');
      return;
    case self.RESULT_NOT_EXIST:
      self.$toast.error('Does not exist.');
      return;
    case self.RESULT_EMAIL_NOT_EXIST:
      self.$toast.error(self.$t('alert_email_not_exist'));
      return;
    case self.RESULT_PHONE_NOT_EXIST:
      self.$toast.error(self.$t('alert_phone_not_exist'));
      return;
    case self.RESULT_USERNAME_NOT_EXIST:
      self.$toast.error(self.$t('alert_username_not_exist'));
      return;
    case self.RESULT_USER_NOT_EXIST:
      self.$toast.error('User does not exist.');
      return;
    case self.RESULT_NOT_OWNER:
      self.$toast.error('You are not the owner.');
      return;
    case self.RESULT_NOT_ORGANIZER:
      self.$toast.error('You are not a Business User');
      return;
    case self.RESULT_USER_UNAPPROVED:
      self.$toast.error('User is not approved yet.');
      return;
    case self.RESULT_WITHDRAW_HAS_PENDING:
      self.$toast.error(self.$t('alert_pending_withdraw'));
      return;
    case self.RESULT_WITHDRAW_MAX_HALF:
      self.$toast.error(self.$t('alert_max_50_percent'));
      return;
    case self.RESULT_WITHDRAW_OVER_BALANCE:
      self.$toast.error(self.$t('alert_over_balance'));
      return;
    case self.RESULT_WITHDRAW_LIVE_EVENT:
      self.$toast.error(self.$t('alert_request_payout_after_end_event'));
      return;
    default:
      self.$toast.error(data);
      return;
  }
}

// loader: spinner, dots, bars
export function showLoading(self, type = 0, isDrink = false) {
  const loader = self.$loading.show({
    loader: type === 1 ? 'dots' : (type === 2 ? 'bars' : 'spinner'),
    backgroundColor: '#636F83',
    color: isDrink ? '#A10559' : '#4B104F'
  });
  return loader;
}